import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import {AuthProvider} from './context/AuthContext'
import {BlogProvider} from './context/BlogContext'
import SideNav from './components/SideNav'
import Home from './Home'
import Login from './Login'
import Post from './Post'
import About from './About'
import Settings from './Settings'
import PostEditor from './PostEditor'
import {Subtitle} from './components/Subtitle'
import './css/pico.css';
import './css/anyareads.css'
import titlesvg from './img/titlesvg.png'

function App() {
  
  return (
    <BlogProvider>
    <AuthProvider>
    
    <section>
        <header className='container'>
        <div className='hero'>
        <hgroup>
          
          <h1>Anya Reads</h1>
          <Subtitle />
        </hgroup>
        </div>
        </header>
        <main className='container'>
        <Router>
        <div id='main-grid' className='grid'>
    
      <Routes>
        <Route exact path="/"  element={<Home />} />
        
        <Route element={<Home />} />
        
        <Route path='post/:slug' element={<Post />} />
        <Route path='edit-post/:slug' element={<PostEditor />} />
        <Route path='new-post' element={<PostEditor />} />
        <Route path='settings' element={<Settings />} />
        <Route path='about' element={<About />} />
      </Routes>
    
        <div id='nav'>
            <SideNav />
          </div>
        </div>
        </Router>
        <Login />
        </main>  
      </section>
    
    </AuthProvider>
    </BlogProvider>
  );
}

export default App;
