import {createContext, useContext, useState, useCallback, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'

import { db, fire, store } from '../util/firebase'
import { deleteDoc } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const BlogContext = createContext()

export const useBlog = () => {
    return useContext(BlogContext)
}

export const BlogProvider = (props) => {
    const {
        collection, 
        query, 
        onSnapshot,
        querySnapshot,
        orderBy,
        where, 
        getDocs, 
        getDoc, 
        addDoc, 
        updateDoc, 
        setDoc,
        doc,
        ref,
        uploadBytes,
        getDownloadURL,
        deleteObject
      } = fire

      const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false)
      const [loggedIn, setLoggedIn] = useState(false)
      const [posts, setPosts] = useState([])
      const [settings, setSettings] = useState([])
      const [theme, setTheme] = useState()
      
      const handleStyle = (props) => {
        const r = document.querySelector(':root')
        const rStyle = getComputedStyle(r)
        r?.style?.setProperty(props?.param, props?.value)
      }

      useEffect(()=>{
        fetchSettings()
        fetchAllPosts()
      },[])

      useEffect(()=>{
        document.body.setAttribute('data-theme', theme)
        document.documentElement.setAttribute('data-theme', theme)
      },[theme])

      const rootContainer = document.getElementById('root')
      const setDataTheme = (theme) => setTheme(theme)

      console.log(
          'BlogContext:',
          'loggedIn:', loggedIn,
          'settings:', settings,
          'theme', theme,
          'posts:', posts
      )

      const fetchDate = () => {
        const currentDate = new Date()
        return currentDate.toISOString().substring(0, 10)
      }
      
      const usePost = (curr) => {
        return posts?.filter(f=>f?.id === curr) || fetchAllPosts()?.filter(f=>f?.id === curr)
      }

      const fetchSettings = async() => {
        const q = query(collection(db, "settings"))
        const settings = await onSnapshot(q, (querySnapshot) => {
        let arr = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }))
            console.log('Fetched settings')
            setSettings(arr)
            let [currentTheme] = arr?.filter(f=>f.id === 'general')?.map(i => i?.currentTheme)
            setTheme(currentTheme)
        })
      }

      const fetchAllPosts = () => {
        const postsRef = collection(db, 'posts')
        const q = query(postsRef, orderBy('date', 'desc'))
        const posts = onSnapshot(q, (querySnapshot) => {
        let arr = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }))
            console.log('Fetched posts')
            setPosts(arr)
        })
    }
    
    const submitPost = async(e) => {
        await addDoc(collection(db, 'posts'), {
            ['date']: fetchDate(),
            ...e?.payload
        })
    }

    const updatePost = async(e) => {
        const docRef = doc(db, 'posts', e?.id)
        await updateDoc(docRef, {
            ['date']: fetchDate(),
            ...e?.payload
          })
    }

    const deletePost = async(e) => {
        const docRef = doc(db, 'posts', e?.id)
        await deleteDoc(docRef)
    }

    const updateSettings = async(e) => {
        const docRef = doc(db, 'settings', 'general')
        await updateDoc(docRef, {
            [e?.key]: e?.value
          })
    }

    const uploadHeroImg = async(e) => {
        const storage = getStorage()
        const storageRef = ref(storage, `images/${e?.id}`)
        await uploadBytes(storageRef, e?.image)
        return  getDownloadURL(storageRef)
    }

    const deleteHeroImg = async(e) => {
        const storage = getStorage()
        const deleteImgRef = ref(storage, `images/${e?.id}`)
        await deleteObject(deleteImgRef)
        return  console.log('Image removed...')
    }

    // const fetchStyle = () => {
    //     const q = query(collection(db, "style"))
    //     const styles = onSnapshot(q, (querySnapshot) => {
    //     let arr = querySnapshot.docs.map(doc => ({
    //         id: doc.id,
    //         ...doc.data()
    //     }))
    //         console.log('Fetched posts')
    //         setStyle(arr)
    //     })
    // }

    const value = {
        posts,
        fetchAllPosts,
        usePost,
        submitPost,
        updatePost,
        deletePost,
        updateSettings,
        uploadHeroImg,
        deleteHeroImg,
        setDataTheme,
        theme,
        settings,
        useNavigate,
        setLoggedIn,
        loggedIn,
        open: isLoginDialogOpen,
        openLogin: useCallback((e)=>setIsLoginDialogOpen(e))
      }
      return(
        <BlogContext.Provider value={value}>
            {props?.children}
        </BlogContext.Provider>
    )
    }