import { useNavigate } from "react-router-dom"
import ReactCommonmark from 'react-commonmark'
const PostList = (props) => {
    const navigate = useNavigate()
    const handleClick = (e) => {
        navigate(`/post/${e?.payload}`)
    }
    return(
        <>
            <h2>Posts</h2>
            {props?.loggedIn ? 
                <>
                {props?.posts?.map(post => 
                    <article onClick={()=>handleClick({payload: post?.id})}>
                    <div id='post-list' className='grid'>
                        <div className='hero-thumb'>
                            {post?.hero ? <img src={post?.hero} id='hero-thumb' /> : <div className='hero-placeholder' />}
                        </div>
                        <div>
                        <hgroup>
                            <h2>{post?.title}</h2>
                            <h5>{post?.date}</h5>
                        </hgroup>
                        
                        <div id='post-preview'>
                            <div className='post-preview'>
                                <ReactCommonmark source={post?.post || ''} />
                            </div>
                            {post?.draft ? <div id='post-draft'>DRAFT</div> : ''}
                        </div>
                        </div>
                        </div>
                        
                        
                        
                    </article>)} 
                    </> :
                    <>
                    {props?.posts?.map(post => 
                        <article onClick={()=>handleClick({payload: post?.id})}>
                    <div id='post-list' className='grid'>
                        <div className='hero-thumb'>
                            {post?.hero ? <img src={post?.hero} id='hero-thumb' /> : <div className='hero-placeholder' />}
                        </div>
                        <div>
                        <hgroup>
                            <h2>{post?.title}</h2>
                            <h5>{post?.date}</h5>
                        </hgroup>
                        
                        <div id='post-preview'>
                            <div className='post-preview'>
                                <ReactCommonmark source={post?.post || ''} />
                            </div>
                            
                        </div>
                        </div>
                        </div>
                        
                        
                        
                    </article>
              
            )}
            </>
            }
        </>
    )
}
export default PostList