import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { 
  getFirestore, 
  collection, 
  query, 
  where, 
  orderBy,
  onSnapshot,
  doc, 
  getDoc, 
  getDocs, 
  updateDoc, 
  setDoc, 
  addDoc, 
  deleteDoc,
  arrayUnion, 
  arrayRemove 
} from 'firebase/firestore'
import { 
  getStorage, 
  ref, 
  uploadBytes, 
  getDownloadURL, 
  deleteObject } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyACFwWmmCBM087XclT2oyVfpH81jtomCps",
  authDomain: "anyareads-d4a52.firebaseapp.com",
  projectId: "anyareads-d4a52",
  storageBucket: "anyareads-d4a52.appspot.com",
  messagingSenderId: "342768929680",
  appId: "1:342768929680:web:25c0d5443d2800a86dc115"
};

const app = initializeApp(firebaseConfig)

export const fire = {
  collection, 
  query, 
  orderBy,
  where, 
  doc, 
  getDoc, 
  getDocs, 
  updateDoc, 
  setDoc, 
  addDoc, 
  deleteDoc,
  getStorage,
  ref,
  onSnapshot,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  arrayUnion,
  arrayRemove
};
export const auth = getAuth(app); 
export const db = getFirestore(app);
export const store = getStorage(app); 