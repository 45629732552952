import { useBlog } from "./context/BlogContext";
import ReactCommonmark from 'react-commonmark'

const About = () => {
    const {settings} = useBlog()
    const [general] = settings?.filter(f=> f?.id === 'general')
    return(
        <article>
            <section id='aboutImg'>{general?.aboutImg ? <img src={general?.aboutImg} id='hero' /> : ''}</section>
            <h1>{general?.aboutTitle || ''}</h1>
            <ReactCommonmark source={general?.aboutDesc || ''} />
        </article>
    )
}
export default About