import { useState, useEffect, useRef, useCallback } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Editor } from '@tinymce/tinymce-react'
import { useBlog } from './context/BlogContext'
import { usePostEditor } from './hooks/usePostEditor'

const PostEditor = (props) => {
    const {slug} = useParams()
    const editorRef = useRef(null)
    const {
        submitPost, 
        updatePost, 
        deletePost, 
        uploadHeroImg,
        deleteHeroImg, 
        posts, 
        useNavigate} = useBlog()
    const navigate = useNavigate()
    const [post, setPost] = useState('')
    const [postData, setPostData] = useState('')
    
    useEffect(()=> {
        try{
            let [curr] = slug ? posts?.filter(f=>f?.id === slug) : ''
            setPost(curr || '')
            setPostData(curr?.post)
        } catch {
            handleNew()
        }
    },[])

    const handleNew = () => {
        const docRef = submitPost({payload: {
            ...post
        }})
        setPost({ id: docRef.id})
        console.log('saved')
    }

    const handleSubmit = (e) => {
        !e?.update ? 
        setPost({ id: submitPost({payload: {
            ...post
        }}).id}) :  
        updatePost({id: post?.id, payload: {
            ...post, 
            ['post']: editorRef.current.getContent()
        }}) 
        navigate('/')
    }

    const handleChange = (e) => {
        e.preventDefault()
        const {name, value} = e.target
        console.log(e)
        setPost({...post, [name]: value})
    }

    const handleFileChange = async(e) => {
        const img = e.target.files[0]
        const urlRef = await uploadHeroImg({id: post?.id, image: img})
        setPost({...post, hero: urlRef})
    }

    const handleDeleteImg = async(e) => {
        await deleteHeroImg({id: post?.id, image: post?.hero})
        setPost({...post, hero: ''})
    }

    const handleDraftChange = (e) => {
        setPost({...post, ['draft']: post?.draft ? !post?.draft : true})
    }

    const handleDelete = (e) => {
        deletePost({id: e?.delete})
        navigate('/')
    }

    return(
        <section id='post-editor'>
            <label htmlFor='title'>Post Title
                <input type='text' id='title' name='title' value={post?.title} onChange={handleChange} />
            </label>
            <label htmlFor='hero-img'>Hero Image
                <section>
                    {post?.hero ? 
                        <div className='grid'>
                            <img src={post?.hero} id='hero' onClick={handleDeleteImg} />
                        </div> : 
                        <input type='file' id='hero-img' name='hero' onChange={handleFileChange} /> 
                    }
                </section>
            </label>
            <section>
            <Editor
                apiKey='ztjy6vniszcwdo9afxwqeerknzgoiqruwto8buj0kzhk3nij'
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={postData || 'New Post'}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />    
            <label htmlFor='draft'>
                <input type='checkbox' role='switch' id='draft' name='draft' onChange={handleDraftChange} checked={post?.draft} />
                Draft
            </label>
            </section>

            <footer>
                <div className='grid'>
                    <button onClick={()=>handleSubmit({update: slug ? true : false})}>Save</button>
                    <button onClick={()=>handleDelete({delete: slug})} className='secondary'>Delete</button>
                </div>
            </footer>
        </section>
    )
}
export default PostEditor