import { useBlog } from "../context/BlogContext"
import { useAuth } from "../context/AuthContext"
const SideNav = (props) => {
  const {useNavigate, openLogin, loggedIn} = useBlog()
  const {logOutUser} = useAuth()
  const navigate = useNavigate()

  const handleClick = (e) => {
    navigate(e)
  }

  const handleLogin = () => {
    openLogin(true)
  }

    return(
        <aside>
            <nav>
              <ul>
              {loggedIn ? 
                  <>
                  <li><strong>Admin</strong></li>
                    <li><a onClick={()=>handleClick('/new-post')}>New Post</a></li>
                    <li><a onClick={()=>handleClick('/settings')}>Settings</a></li>
                    <hr />
                  </>
                : ''}
                <li><a onClick={()=>handleClick('/')}>Home</a></li>
                <li><a onClick={()=>handleClick('/about')}>About</a></li>
                <li>
                  {!loggedIn ? 
                    <a onClick={()=>handleLogin()}>Login</a> :
                    <a onClick={()=>logOutUser()}>Logout</a>
                  }
                </li>
              </ul>
            </nav>
        </aside>
    )
}
export default SideNav