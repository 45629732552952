import React, { useContext, useState, useEffect, createContext } from 'react';
import { onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { useBlog } from './BlogContext';
import { auth } from '../util/firebase';

const AuthContext = createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({children}) {
    const {setLoggedIn, loggedIn} = useBlog()
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password)
  }

    function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

    const unsubscribe = async() => {
        onAuthStateChanged(auth, user => {
       
        setLoggedIn(!loggedIn)
        
    })
  }

  useEffect(() => {
    unsubscribe()
    
  },[])

  const logOutUser = async() => {
    await auth.signOut()
    setLoggedIn(false)
  }

  const value = {
    signup,
    login,
    logOutUser
  }

  return (
    <AuthContext.Provider value={value}>
    {children}
    </AuthContext.Provider>
  )
}