import {useState, useRef, useEffect} from 'react'
import { useAuth } from './context/AuthContext'
import { useBlog } from './context/BlogContext'

const Login = () => {
    const [data, setData] = useState('')
    const {login} = useAuth()
    const {open, openLogin, setLoggedIn, loggedIn} = useBlog()
    
    const handleChange = (e) => {
        e.preventDefault()
        const {name, value} = e.target
        setData({...data, [name]: value})
        console.log(data)
    }
    
    const handleSubmit = async() => {
        try{
            await login(data?.user, data?.pass)
        }
        catch{
            console.log('Error logging in...')
        }
        setLoggedIn(true)
        openLogin(false)
    }
    console.log('loggedIn', loggedIn)
    return (
        <dialog open={open}>
            <article>
                
                <form>
                
                <label htmlFor='email'>Email
                    <input type='text' name='user' value={data?.user || ''} onChange={handleChange} />
                </label>
                
                <label htmlFor='pass'>Password
                    <input type='password' name='pass' value={data?.pass || ''} onChange={handleChange}  />
                </label>

                
                
                </form>
                <footer><button onClick={()=>handleSubmit()}>Login</button>
                <button onClick={()=>openLogin(false)} className='secondary'>Cancel</button></footer>
            
            </article>
        </dialog>
    )
}
export default Login