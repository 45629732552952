import {useEffect} from 'react'
import {useBlog} from './context/BlogContext'
import PostList from './components/PostList'

const Home = (props) => {
    const {posts, fetchAllPosts, loggedIn, useNavigate} = useBlog()
    const navigate = useNavigate()
    // useEffect(()=> {
    //     fetchAllPosts()
    // },[])
    console.log('Posts:', posts)
    // const createNewPost = () => {
    //     navigate('/new-post')
    // }
    return(
        
          <div id='post-list'>
            <PostList posts={posts} loggedIn={loggedIn} />
          </div>
          
    )
}
export default Home